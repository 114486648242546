import {
  Box,
  Center,
  Text,
  List,
  ListItem,
  Spacer,
} from "@chakra-ui/react";
import React from "react";

export default function Menu() {
  return (
    <Box minH="lg"  bg="rgba(255,255,255,0.1)" backgroundPosition={"center"} backgroundImage={"https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/IMG_20211107_073917_418.png?alt=media&token=05ffbea4-4e34-4253-8842-860be5906490"}>
      <Center flexDirection={"column"} color="white" ListItemShadow="outline">
      <List justifyContent={"center"}>
        <Text py={4} fontSize={"4xl"}>
          Menú
        </Text>
        <Spacer />
        
        <ListItem py={1} fontSize={"3xl"} decoration="underline" color={"#7BD261"}>
          Arroces
        </ListItem>
        <ListItem>Arroz Blanco</ListItem>
        <ListItem>Arroz Amarillo con Curcuma</ListItem>
        <ListItem>Arroz con Gandules</ListItem>
        <ListItem>Arroz Congrí</ListItem>
        <ListItem>Arroz Verde con Cilantro</ListItem>
        <ListItem>Arroz con Albahaca y Gomasio</ListItem>
        <ListItem>Arroz con Coco, Especias y Almendras</ListItem>
        <ListItem>Arroz a la Jardinera</ListItem>
        
        <Spacer />
        <ListItem py={1} fontSize={"3xl"} decoration="underline" color={"#7BD261"}>
          Proteinas
        </ListItem>
        <ListItem>Habichuelas</ListItem>
        <ListItem>Lentejas</ListItem>
        <ListItem>Garbanzos</ListItem>
        <ListItem>Tofu</ListItem>
        <ListItem>Steak Vegetal</ListItem>
        <ListItem>Soya Molida</ListItem>
        <ListItem>Vegetales Salteados</ListItem>
        <ListItem>Jaca</ListItem>
        
        <Spacer />
        
        <ListItem py={1} fontSize={"3xl"} decoration="underline" color={"#7BD261"}>
          Pastas
        </ListItem>
        <ListItem>Salsa Alfredo de Cashews</ListItem>
        <ListItem>Pesto con Albahaca</ListItem>
        <ListItem>Pesto con Oregano</ListItem>
        <ListItem>Marinara</ListItem>
        <ListItem>Oleo</ListItem>
        <ListItem>Gnoquis</ListItem>
        <ListItem>Mantequilla de hierbas</ListItem>
        
        <Spacer />
        
        <ListItem py={1} fontSize={"3xl"} decoration="underline" color={"#7BD261"}>
          Ensaladas
        </ListItem>
        <ListItem>Mixta de Hojas</ListItem>
        <ListItem>Mixta de Germinados</ListItem>
        <ListItem>Granos</ListItem>
        <ListItem>Cole Slaw</ListItem>
        <ListItem>Ensalada de Papa Fria</ListItem>
        <ListItem>Ensalada de Coditos</ListItem>
        <ListItem>Ensalada Cruda de Chayote y Remolacha</ListItem>
        
        <Spacer />
        
        <ListItem py={1} fontSize={"3xl"} decoration="underline" color={"#7BD261"}>
        Sopas
        </ListItem>
        <ListItem>Asopao de Gandules</ListItem>
        <ListItem>Sancocho</ListItem>
        <ListItem>Diosa verde</ListItem>
        <ListItem>Chowder de Vegetales</ListItem>
        <ListItem>Asiaticas</ListItem>
        <ListItem>Curry</ListItem>
        <ListItem>Granos y Vegetales</ListItem>
        <ListItem>Callaloo</ListItem>
        
        <Spacer />
        
        <ListItem py={1} fontSize={"3xl"} decoration="underline" color={"#7BD261"}>
        Postres de Cumpleaños
        </ListItem>
        <ListItem>Chocolate</ListItem>
        <ListItem>Vainilla</ListItem>
        <ListItem>Fresa</ListItem>
        <ListItem>Marmoleado</ListItem>
        <ListItem>Coco</ListItem>
        <ListItem>Limón</ListItem>
        <ListItem>Guineo</ListItem>
        <ListItem>Zanahoria</ListItem>
        
        <Spacer />
        
        <ListItem py={1} fontSize={"3xl"} decoration="underline" color={"#7BD261"}>
        Pasteles Veganos
        </ListItem>
        <ListItem>12 - $24.00</ListItem>
        <ListItem>24 - $48.00</ListItem>
      
        <Spacer />
        
        <ListItem py={1} fontSize={"3xl"} decoration="underline" color={"#7BD261"}>
        Alcapurrias
        </ListItem>
        <ListItem>12 - $18.00</ListItem>
        
        <Spacer py={2}/>
        
        <ListItem fontSize={"lg"}>Y Mucho Más…</ListItem>
        </List>
        <Spacer py={4}/>
      </Center>
    </Box>
  );
}
