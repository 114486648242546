import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  AspectRatio,
  Image,
  Link
} from "@chakra-ui/react";

import { Link as Linker } from "react-router-dom";

const Logo = (props) => {
  return <Image src={"https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/Logo.jpeg?alt=media&token=d2290055-67bd-4b24-941e-a1995eed270b"} alt="Logo" maxH={"150px"} />;
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function FooterWithLogoCentered() {
  return (
    <Box bg={"#7BD261"} color={"#FFF"}>
      <Container as={Stack} maxW={"7xl"} py={7}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={12}>
          <Stack align={"flex-start"}>
            <ListHeader>Chakra Verde</ListHeader>
            <Linker to={"/"}>Home</Linker>
            <Linker to={"/Blog"}>Blog</Linker>
            {/* <Linker to={"/Videos"}>Videos</Linker> */}
            <Linker to={"/Menu"}>Menu</Linker>
            <Linker to={"/Servicios"}>Servicios</Linker>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Legal</ListHeader>
            <Link href={"#"}>Poliza de "Cookies"</Link>
            <Link href={"#"}>Poliza de Privacidad</Link>
            <Link href={"#"}>Terminos de Servicio</Link>
            <Link href={"#"}>Estatus</Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Siguenos</ListHeader>
            <Link href={"https://www.facebook.com/chakra.verde.7"}>
              Facebook
            </Link>
            <Link href={"https://www.instagram.com/c_v_cocinaagroecologica/"}>
              Instagram
            </Link>
            <Link
              href={"https://www.youtube.com/channel/UCobYXfpNg3hmBUbFCqMRAvQ"}
            >
              Youtube
            </Link>
            <Link href={"mailto:chakraverdehuerta@gmail.com"}>Email</Link>
          </Stack>
          <Stack>
            <AspectRatio ratio={16 / 13}>
              <div onClick={
                    "https://goo.gl/maps/RuVYYHTFHGiumx29A"
                  }>
                <iframe
                  onTap={
                    "https://goo.gl/maps/RuVYYHTFHGiumx29A"
                  }
                  title={"Location"}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3786.3818954689937!2d-67.1732748!3d18.375462299999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c02bb34ffa9ce1b%3A0xa51f9362f25c5311!2sChakra%20Verde%20Cocina%20Agroecologica!5e0!3m2!1sen!2spr!4v1666801747709!5m2!1sen!2spr"
                  alt=""
                />
              </div>
            </AspectRatio>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box py={6}>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Logo />
        </Flex>
        <Text pt={6} fontSize={"sm"} textAlign={"center"}>
          © 2022 Chakra Verde. Todos los derechos reservados.
        </Text>
      </Box>
    </Box>
  );
}
