import {
  Box,
  Image,
  Stack,
  Text,
  Flex,
  Heading,
  Center,
} from "@chakra-ui/react";

export default function Biografia() {
  return (
    <Box minH="xl" flexDirection={"column"}>
      <Center flexDirection={"column"}>
        <Heading
          pt={4}
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "3xl", lg: "5xl" }}
        >
          Biografìa
        </Heading>
        <Stack
          align={"center"}
          pt={4}
          spacing={{ base: 8, md: 10 }}
          direction={{ base: "column", md: "row" }}
        >
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            w={"full"}
          >
            <Box
              position={"relative"}
              height={"450px"}
              rounded={"2xl"}
              boxShadow={"2xl"}
              width={"contain"}
              overflow={"hidden"}
            >
              <Image
                alt={"Hero Image"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/IMG_20220408_074007_294.webp?alt=media&token=510cd3b5-0fbc-499c-9ed3-4dd90a6a8c06"
                }
              />
            </Box>
          </Flex>
          <Stack px={12} flex={1} spacing={{ base: 5, md: 10 }}>
            <Text as="p" fontSize="lg" textAlign={"justify"}>
              Desde pequeña siempre he tenido gran admiración por la naturaleza
              y el bienestar que nos trae al estar en ella, usar de manera sana
              todo lo que nos provee.
            </Text>
            <Text as="p" fontSize="lg" textAlign={"justify"}>
              Me encantaba ver como mi familia siempre se unía para compartir y
              cocinar, pasar un buen rato, admiraba a mis abuelas, mi madre y
              tíos que siempre cocinaban para toda una tribu, aunque desconocía
              el daño que nos hacíamos al preparar algunos alimentos por el alto
              contenido de condimentos procesados y grasas, o ni tan siquiera
              saber su procedencia, no ser capaces de mencionar sus componentes.
            </Text>
            <Text as="p" fontSize="lg" textAlign={"justify"}>
              Mi interés y motivación en aprender sobre la siembra y la
              alimentación saludable fue creciendo y se definió cuando cumplí
              mis 20 años, entonces consideré tomar cursos cortos para educarme
              sobre siembra orgánica y cocina saludable.
            </Text>
            <Text as="p" fontSize="lg" textAlign={"justify"}>
              Cuando diagnosticaron a mi abuela materna con alzheimer en el
              2006. Y ver a otros familiares queridos que fueron desarrollando
              diversas condiciones de salud, debido a sus hábitos alimenticios,
              de cuidado personal, estados emocionales y psicológicos. Quise
              adentrarme más a aprender y conocer más sobre cómo prevenir
              enfermedades y tener una buena calidad de vida a través de
              nuestras elecciones diarias.
            </Text>
          </Stack>
        </Stack>
        <Stack
          px={12}
          py={{ base: 5, md: 10 }}
          flex={1}
          spacing={{ base: 5, md: 10 }}
        >
          <Text as="p" fontSize="lg" textAlign={"justify"}>
            Llevaba tiempo estudiando Artes Culinarias para ejercerlo en
            profesión y así poder tener más oportunidades, anduve mientras
            orientándome sobre la alimentación, los transgénicos, modificación
            genética, floruro, metales pesados y otros contaminantes que
            consumimos a través de los alimentos, el agua y los efectos dañinos
            que traen a nuestra salud. Y también sobre cómo genéticamente
            heredamos ciertas condiciones de salud.
          </Text>
          <Text as="p" fontSize="lg" textAlign={"justify"}>
            Me preocupa la lista extensa de condiciones crónicas y cómo aumentan
            los casos entre nuestra sociedad.
          </Text>
          <Text as="p" fontSize="lg" textAlign={"justify"}>
            Esto me llevó a estudiar y aprender en el año 2008 sobre la
            agricultura orgánica, en los varios cursos que tomé como Huerto
            Casero, Plantas Medicinales, Sistemas Hidropónicos. Luego contacté a
            la Finca Plenitud en Las Marías donde permanecí por casi año y
            medio, aprendiendo y ayudando en las tareas de la finca y a cocinar
            para grupos de estudiantes.
          </Text>
          <Text as="p" fontSize="lg" textAlign={"justify"}>
            Tuve la oportunidad de estudiar en el Instituto Ann Wigmore un curso
            sobre detoxificación y alimentación viva donde aprendí el valor
            esencial de consumir altas cantidades de alimentos frescos, verdes,
            vivos y libres de agrotóxicos para el control y prevención de
            enfermedades crónicas y la práctica del buen vivir. Eventualmente
            trabajé en la cocina del instituto una temporada.
          </Text>
          <Text as="p" fontSize="lg" textAlign={"justify"}>
            He trabajado en varios restaurantes locales donde aprendí diversas
            recetas locales e internacionales, también sobre el servicio y
            funcionamiento de negocios.
          </Text>
          <Text as="p" fontSize="lg" textAlign={"justify"}>
            Esto me motivó a crear en el 2015 lo que es hoy Chakra Verde Cocina
            Agroecológica, y así poder ofrecer opciones de alimentación
            saludable deliciosas hechas con mucho amor a la comunidad.
          </Text>
          <Text as="p" fontSize="lg" textAlign={"justify"}>
            Confeccionando una variedad de alimentos, postres, jugos
            vegetarianos, veganos o verdes, para cualquier ocasión, deseando
            promover mayormente la agricultura ecológica local.
          </Text>
        </Stack>
      </Center>
    </Box>
  );
}

// <Image maxHeight={"xl"} src={'https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/IMG_20220408_074007_294.webp?alt=media&token=510cd3b5-0fbc-499c-9ed3-4dd90a6a8c06'}/>
