import {
  Box,
  Image,
  Stack,
  Heading,
  Text,
  Button,
  List,
  ListIcon,
  ListItem,
  Flex,
  IconButton,
  Divider,
  Spacer,
  Container,
  SimpleGrid,
  HStack,
  Icon,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { CheckIcon } from "@chakra-ui/icons";

const features = [
  {
    id: 1,
    title: "Raw/Vegano/Vegetariano",
    text: "",
  },
  {
    id: 2,
    title: "Catering",
    text: "",
  },
  {
    id: 3,
    title: "Chef Personal",
    text: "",
  },
  {
    id: 4,
    title: "Huerto a la Mesa @chakra_verde",
    text: "",
  },
  {
    id: 5,
    title: "Apoyo Siembras Agroecológicas",
    text: "",
  },
  {
    id: 6,
    title: "Postres",
    text: "",
  },
  {
    id: 7,
    title: "Jugos y Pócimas",
    text: "",
  },
];

export default function Servicios() {
  return (
    <Box minH="xl" flexDirection={"column"}>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack px={12} flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
          >
            <Text
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                left: 0,
                bg: "whatsapp.500",
                zIndex: -1,
              }}
            >
              Servicios
            </Text>
            <br />
            <Text textAlign={"center"} as={"span"} color={"whatsapp.500"}>
              Plan de Purificación
            </Text>
          </Heading>
          <Text color={"gray.500"}>
            Ofrecemos apoyo y acompañamiento durante 5 días para purificar el
            cuerpo y alma con una alimentación totalmente basada en plantas,
            fermentos, jugos verdes e hidratación para restablecer la flora
            intestinal y el cuerpo en general.
          </Text>
          <Text color={"gray.500"}>
            Reduciendo o idealmente eliminando el consumo de bebidas
            alcohólicas, nicotina, cafeína, azúcares refinadas y sales.
          </Text>
          <Text color={"gray.500"}>
            Esto ayuda al cuerpo a eliminar toxinas, cambiar hábitos
            alimenticios, asimilar vitaminas, minerales y nutrientes de forma
            natural. Bajar los niveles de cortisol, aumentar la inmunidad,
            controlar o prevenir enfermedades crónicas, bajar de peso entre
            otros beneficios.
          </Text>
          <Text color={"gray.500"}>
            Darle tiempo al cuerpo para restablecer tu sistema en general le
            ayuda a regenerarse y ganar más fuerza y vitalidad. Para que
            comiences un año nuevo, renovado y revitalizado, con una mente clara
            y conciencia asertiva.
          </Text>
          <List spacing={3}>
            <ListItem fontSize={"xl"}>El Plan Incluye: </ListItem>
            <ListItem>
              <ListIcon as={CheckIcon} color="green.500" />
              Orientación y Apoyo en el transcurso de la purificación.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckIcon} color="green.500" />
              Manual con plan diario y recetas crudiveganas.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckIcon} color="green.500" />
              Preparados de Rejuvelac, Jugos verdes y Leches Vegetales/ Hechos
              por @c_v_cocinaagroecologica.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckIcon} color="green.500" />
              Germinados y Verdes para complementar platos y hacer sopas
              energéticas o batidos.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckIcon} color="green.500" />
              Lista de compras.
            </ListItem>
            <ListItem>
              Referencias para terapias complementarias para llevar tu
              purificación a otros niveles (Asesoramiento, Coaching, Yoga,
              Masajes, Reiki, Colónicos)
            </ListItem>
          </List>
          <Text color={"gray.500"}>
            *Antes de realizar cualquier programa de purificación o alimentación
            es ideal consultar con su médico.
          </Text>
          <Text color={"gray.500"}>
            Este no intenciona remplazar ninguna terapia o tratamiento de
            medicinas convencionales. A menos que desee voluntariamente hacer
            una transformación en sus hábitos diarios alimenticios.
          </Text>
          <Text color={"gray.500"}>
          *Area Oeste, Puerto Rico 💚
          </Text>
          {/* <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
            <Button
              rounded={"full"}
              size={"lg"}
              fontWeight={"normal"}
              px={6}
              colorScheme={"whatsapp"}
              bg={"whatsapp.400"}
              _hover={{ bg: "whatsapp.500" }}
            >
              Comienza
            </Button>
            <Button rounded={"full"} size={"lg"} fontWeight={"normal"} px={6}>
              Aprende Como funciona
            </Button> 
          </Stack>*/}
        </Stack>
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          <Box
            position={"relative"}
            height={"450px"}
            rounded={"2xl"}
            boxShadow={"2xl"}
            width={"full"}
            overflow={"hidden"}
          >
            <Image
              alt={"Plan de Purificación"}
              align={"center"}
              w={"100%"}
              h={"100%"}
              src={
                "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/png_20220102_202726_0000.png?alt=media&token=ea35475c-1f0b-41e1-a942-f336d639bb77"
              }
            />
          </Box>
        </Flex>
      </Stack>

      <Divider />
      <Spacer py={4} />

      <Box p={4}>
        <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
          <Heading
            fontSize={"3xl"}
            colorScheme={"whatsapp"}
            color={"whatsapp.400"}
          >
            Nuestros Servicios Cuentan Con
          </Heading>
        </Stack>

        <Container maxW={"6xl"} mt={10}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
            {features.map((feature) => (
              <HStack key={feature.id} align={"top"}>
                <Box color={"whatsapp.400"} px={2}>
                  <Icon as={CheckIcon} />
                </Box>
                <VStack align={"start"}>
                  <Text fontWeight={600}>{feature.title}</Text>
                  <Text color={"gray.600"}>{feature.text}</Text>
                </VStack>
              </HStack>
            ))}
          </SimpleGrid>
        </Container>
      </Box>
      <Spacer py={4} />
    </Box>
  );
}
