import { Box, IconButton, useBreakpointValue, Center } from "@chakra-ui/react";
import React from "react";

import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
// And react-slick as our Carousel Lib
import Slider from "react-slick";

const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 750,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function Home() {
  const [slider, setSlider] = React.useState(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "10px" });

  const cards = [
    "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/Menu%20Arreglado.png?alt=media&token=baeba6cf-e773-4fc7-ac25-17b12bd7ce1b",
    "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/Catering%20Banner.jpg?alt=media&token=7926601a-6b74-411f-ae97-1a134eaa9442",
    "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/Cover_20221026_133007_0000.png?alt=media&token=e122baa4-488a-4bce-baf6-a6b38b4480d9",
    "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/jarindindin_1633094956276.jpg?alt=media&token=d78daf88-55b2-492c-a489-df18a0b0076c",
    "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/Tragos%20Rosa%20y%20Azul.jpg?alt=media&token=555ea95a-41d0-4135-9982-e50db3daf7f8",
    "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/20220409_183012.jpg?alt=media&token=472244c6-9e06-434b-bd88-cf96e5da9866",
    "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/20220804_113841.jpg?alt=media&token=b10fbb90-9188-45f4-b318-f9a8d5608513",
    "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/FB_IMG_1655906427974.jpg?alt=media&token=6df9842c-b8bd-46b5-800f-c2eb0fca116e",
    "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/IMG-20220214-WA0036.jpg?alt=media&token=e3673d0d-1637-490f-8334-512ed1adebe7"
  ];

  return (
    <Box minH="3xl">
      <Center flexDirection={"column"}>
        <Box
          position={"relative"}
          height={"3xl"}
          width={"full"}
          overflow={"hidden"}
        >
          {/* CSS files for react-slick */}
          <link
            rel="stylesheet"
            type="text/css"
            charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          {/* Left Icon */}
          <IconButton
            aria-label="left-arrow"
            colorScheme="whatsapp"
            borderRadius="full"
            position="absolute"
            left={side}
            top={top}
            transform={"translate(0%, -50%)"}
            zIndex={2}
            onClick={() => slider?.slickPrev()}
          >
            <BiLeftArrowAlt />
          </IconButton>
          {/* Right Icon */}
          <IconButton
            aria-label="right-arrow"
            colorScheme="whatsapp"
            borderRadius="full"
            position="absolute"
            right={side}
            top={top}
            transform={"translate(0%, -50%)"}
            zIndex={2}
            onClick={() => slider?.slickNext()}
          >
            <BiRightArrowAlt />
          </IconButton>
          {/* Slider */}
          <Slider {...settings} ref={(slider) => setSlider(slider)}>
            {cards.map((url, index) => (
              <Box
                key={index}
                height={"3xl"}
                position="relative"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="contain"
                backgroundImage={`url(${url})`}
              />
            ))}
          </Slider>
        </Box>
      </Center>
    </Box>
  );
}
