import React from "react";

import { ChakraProvider } from '@chakra-ui/react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from "./Pages/Home";
import Blog from "./Pages/Blog";
import Videos from "./Pages/Videos";
import Menu from "./Pages/Menu";
import Servicios from "./Pages/Servicios";

import Header from "./Components/Header";
import FooterWithLogoCentered from "./Components/Footer";
import Biografia from "./Pages/Blogs/biografia";


export default function App() {
  return (
    <ChakraProvider>
      <Router>
      <div>
        <Header/>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/Servicios" element={<Servicios/>}/>
          <Route path="/Menu" element={<Menu/>}/>
          <Route path="/Videos" element={<Videos/>}/>
          <Route path="/Blog" element={<Blog/>}/>
          <Route path="/Blog/Biografia" element={<Biografia />}/>
          <Route path="/" element={<Home />}/>
        </Routes>

        < FooterWithLogoCentered />
      </div>

    </Router>
    </ChakraProvider>
  );
}