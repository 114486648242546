import React from "react";

import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  Stack,
  Image,
  Text,
  Spacer,
} from "@chakra-ui/react";

import { Link as Linker } from "react-router-dom";

import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

const Links = ["Home", "Blog", "Menu", "Servicios"];

const HeaderLogo = (props) => {
  return <Image src={"https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/Logo.jpeg?alt=media&token=d2290055-67bd-4b24-941e-a1995eed270b"} alt="Logo" maxH={"100px"} />;
};

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box bg={"#7BD261"} px={4} py={6}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            bg={"#7BD261"}
            size={"xl"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box>
              <HeaderLogo display={{ md: "none" }} />
            </Box>
          </HStack>

          <Flex alignItems={"center"}>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              <Linker to="/">
                <Text
                  color="white"
                  fontSize="2xl"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: "gray.700",
                  }}
                >
                  {Links[0]}
                </Text>
              </Linker>
              <Linker to="/Blog">
                <Text
                  color="white"
                  fontSize="2xl"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: "gray.700",
                  }}
                >
                  {Links[1]}
                </Text>
              </Linker>
              {/* <Linker to="/Videos">
                <Text
                  color="white"
                  fontSize="2xl"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: "gray.700",
                  }}
                >
                  {Links[2]}
                </Text>
              </Linker> */}
              <Linker to="/Menu">
                <Text
                  color="white"
                  fontSize="2xl"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: "gray.700",
                  }}
                >
                  {Links[2]}
                </Text>
              </Linker>
              <Linker to="/Servicios">
                <Text
                  color="white"
                  fontSize="2xl"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: "gray.700",
                  }}
                >
                  {Links[3]}
                </Text>
              </Linker>
              <Spacer />
              <Spacer />
              <Spacer />
            </HStack>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} py={7} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              <Linker to="/">
                <Text
                  color={"white"}
                  fontSize="md"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: "grey",
                  }}
                >
                  {Links[0]}
                </Text>
              </Linker>
              <Linker to="/Blog">
                <Text
                  color={"white"}
                  fontSize="md"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: "grey",
                  }}
                >
                  {Links[1]}
                </Text>
              </Linker>
              {/* <Linker to="/Videos">
                <Text
                  color={"white"}
                  fontSize="md"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: "grey",
                  }}
                >
                  {Links[2]}
                </Text>
              </Linker> */}
              <Linker to="/Menu">
                <Text
                  color={"white"}
                  fontSize="md"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: "grey",
                  }}
                >
                  {Links[2]}
                </Text>
              </Linker>
              <Linker to="/Servicios">
                <Text
                  color={"white"}
                  fontSize="md"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: "grey",
                  }}
                >
                  {Links[3]}
                </Text>
              </Linker>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
