import React from "react";
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  Container,
  VStack,
} from "@chakra-ui/react";

import { Link as Linker } from "react-router-dom";

const BlogTags = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag
            size={"md"}
            variant="solid"
            colorScheme="whatsapp"
            backgroundColor="whatsapp.400"
            key={tag}
          >
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

export const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

export default function Blog() {
  return (
    <Container maxW={"7xl"} p="12">
      <Heading as="h2" marginTop="5">
        Articulos Recientes
      </Heading>
      <Divider marginTop="5" />
      <Wrap spacing="30px" marginTop="5">
        <WrapItem width={{ base: "100%", sm: "45%", md: "45%", lg: "30%" }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Linker to="/Blog/Biografia">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  <Image
                    transform="scale(1.0)"
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/chakra-verde.appspot.com/o/IMG_20220408_074007_294.webp?alt=media&token=510cd3b5-0fbc-499c-9ed3-4dd90a6a8c06"
                    }
                    alt="some text"
                    objectFit="contain"
                    width="100%"
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: "scale(1.05)",
                    }}
                  />
                </Link>
              </Linker>
            </Box>
            <BlogTags tags={["Biografìa"]} marginTop="3" />
            <Heading fontSize="xl" marginTop="2">
              <Linker to="/Blog/Biografia">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  Biografìa
                </Link>
              </Linker>
            </Heading>
            <Linker to="/Blog/Biografia">
              <Text as="p" fontSize="md" marginTop="2">
                Desde pequeña siempre he tenido gran admiración por la
                naturaleza y el bienestar que nos trae al estar en ella, usar de
                manera sana todo lo que nos provee.Me encantaba ver como mi
                familia siempre se unía para compartir y cocinar, pasar un buen
                rato, admiraba a mis abuelas, mi madre y tíos que siempre
                cocinaban para toda una tribu, aunque desconocía el daño que nos
                hacíamos al preparar algunos...
              </Text>
            </Linker>
            <BlogAuthor name="Jariksa Valle" date={new Date("2022-08-12")} />
          </Box>
        </WrapItem>
      </Wrap>
      {/* <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
          <Heading as="h2">A Que Nos Dedicamos A Escribir </Heading>
          <Text as="p" fontSize="lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            condimentum quam arcu, eu tempus tortor molestie at. Vestibulum
            pretium condimentum dignissim. Vestibulum ultrices vitae nisi sed
            imperdiet. Mauris quis erat consequat, commodo massa quis, feugiat
            sapien. Suspendisse placerat vulputate posuere. Curabitur neque
            tortor, mattis nec lacus non, placerat congue elit.
          </Text>
          <Text as="p" fontSize="lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            condimentum quam arcu, eu tempus tortor molestie at. Vestibulum
            pretium condimentum dignissim. Vestibulum ultrices vitae nisi sed
            imperdiet. Mauris quis erat consequat, commodo massa quis, feugiat
            sapien. Suspendisse placerat vulputate posuere. Curabitur neque
            tortor, mattis nec lacus non, placerat congue elit.
          </Text>
        </VStack> */}
    </Container>
  );
}
